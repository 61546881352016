*{
    margin:0px;
    padding:0px;
    box-sizing: border-box;
    /* background-color: #f8f8f8; */
}

.btn_style{
    background-color:#cb2229;
    padding:7px 17px;
    border:1px solid #cb2229;
    border-radius:5px;
    color:#ffffff;
}
.btn_style:hover{
    background-color:#eb171f;
}
.form-control:focus {
    border-color: #505e8626 !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    transition:.8s;
}
.head_styling{
    font-size:20px;
}
.box_container .box_ul_li_style h5{
    color:#eb171f;
    font-size: 1.5rem;
    font-weight: 500;
}
div#navbarSupportedContent {
    justify-content: end;
}
.box_container .box_ul_li_style p{
    text-align: justify;
    color: #555;
}
.box_container .box_ul_li_style ul li{
    color: #555;
}
.col-xl-6.col-md-6.col-sm-10.box_ul_li_style div {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    padding: 20px;
    height:460px;
    border-radius:10px;
}
.col-xl-6.col-md-6.col-sm-10.box_ul_li_style div:hover{
    background-color: #505e8614;
    transition:.2s;
}
@media only screen and (max-width: 768px){
    .col-xl-6.col-md-6.col-sm-10.box_ul_li_style div {
        height:640px;
    }
  }
@media only screen and (max-width: 576px){
    .col-xl-6.col-md-6.col-sm-10.box_ul_li_style div {
        height:620px;
    }
  }
/* .easycodingschool_navbar .container,.container-fluid.easycodingschool_navbar{
    --bs-gutter-x: 0rem !important;
} */
@media only screen and (min-width: 1140px){
    .nav-item:hover .dropdown-menu{
        display:block !important;
    }
  }
  .program ul.dropdown-menu.border-0{
    box-shadow: 0 10px 25px rgb(0 0 0 / 15%);
  background-color: #fff;
  width:250px;
  padding:0px !important;
  border-top:2px solid #cb2229 !important;
  }
